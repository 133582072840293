._1N6Wze7sTB {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
}
._2hWS5nb5mj {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
}
._3I0jUSSG-A {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  min-height: 30rem;
  background: url(./ttn-console-bg.498252edc489187693a4e32162260925.png) no-repeat;
  -webkit-background-size: 160vh;
  -moz-background-size: 160vh;
  background-size: 160vh;
  background-position: 40vw 20%;
  padding-top: 20vh;
}
@media screen and (max-width: 1080px) {
  ._3I0jUSSG-A {
    -webkit-background-size: 140vh;
    -moz-background-size: 140vh;
    background-size: 140vh;
    background-position: 60vw 20%;
  }
}
@media screen and (max-width: 768px) {
  ._3I0jUSSG-A {
    -webkit-background-size: 160vh;
    -moz-background-size: 160vh;
    background-size: 160vh;
    background-position: 0 20vh;
  }
}
._1bD8uTZ9gz {
  font-size: 1.57rem;
  line-height: 1.4;
  font-weight: 800;
  margin-bottom: 0.5rem;
}
@media screen and (max-width: 768px) {
  ._1bD8uTZ9gz {
    margin-top: 4rem;
  }
}
._19QB5_5Fon {
  display: block;
  margin-bottom: 2rem;
}
