._1x8VymddEs {
  border-bottom: 1px solid #efefef;
  margin-bottom: 1.5rem;
}
._1NrLLWsyZD._15SXP6JvgB {
  padding-bottom: 0;
}
._1NrLLWsyZD._15SXP6JvgB ._1-jN8p-Kuc {
  margin-bottom: calc(1.5rem - 0.570724rem);
}
.HOtGM1N3jG {
  margin-top: calc(3rem - 0.570724rem);
  margin-bottom: calc(1rem - 0.570724rem);
  font-weight: 800;
}
.q6ZXQLjkm7 {
  line-height: 1;
  display: inline-block;
  white-space: nowrap;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
._1-jN8p-Kuc {
  margin-top: calc(2rem - 0.570724rem);
  margin-bottom: calc(2rem - 0.570724rem);
  display: block;
}
._1Is0Aucfht {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: calc(2.5rem - 0.570724rem);
  margin-bottom: calc(1.3rem - 0.570724rem);
}
._3MaIU-FnMV,
._9vUS-yvS53 {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
._9vUS-yvS53 {
  color: #868686;
}
@media screen and (max-width: 768px) {
  ._9vUS-yvS53 {
    display: none;
  }
}
.K32-moT8U4 {
  margin-top: 0;
  margin-bottom: 3rem;
}
@media screen and (max-width: 768px) {
  .K32-moT8U4 {
    margin-bottom: 2rem;
  }
}
._33e1IA75BI {
  line-height: 1;
  display: inline-block;
  white-space: nowrap;
  margin-left: 1rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
._1RYijQnJ5E:first-child {
  margin-right: 0.25rem;
}
._3h5kQxAImR {
  color: #2380ff;
}
