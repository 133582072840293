._3NF4aAiC-l {
  margin-top: 4rem;
}
._3NF4aAiC-l span {
  position: relative;
  bottom: 4px;
  color: #ff5c5c;
  margin-right: 0.75rem;
}
._3NF4aAiC-l h2 {
  display: inline;
}
._1CblEO0Gev {
  margin-top: 3rem;
}
