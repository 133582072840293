._2Et_G1HGip {
  color: #868686;
  font-style: italic;
}
._2Et_G1HGip:before {
  content: '<';
}
._2Et_G1HGip:after {
  content: '>';
}
