._3EQh2jxkLI {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
._3EQh2jxkLI:not(:last-child) {
  padding-right: 0.75rem;
}
._3EQh2jxkLI ._17-vabAqGK {
  color: #868686;
}
._3EQh2jxkLI ._17-vabAqGK:after {
  content: ':';
}
._3EQh2jxkLI ._2WN8QiVT0- {
  margin-left: 0.5rem;
}
