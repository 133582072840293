._3yt86I59ov .select__option {
  padding: 0.5rem 0.75rem;
}
._3yt86I59ov .select__option--is-selected {
  background-color: #2380ff;
}
._3yt86I59ov .select__option--is-focused:not(.select__option--is-selected) {
  background-color: #f3f3f3;
}
._3yt86I59ov .select__control--is-focused {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #2380ff;
  color: #000;
}
._3yt86I59ov .select__control {
  width: 100%;
  min-height: 2.2rem;
  border-radius: 3px;
}
._3yt86I59ov .select__menu {
  z-index: 9;
}
._3yt86I59ov .select__dropdown-indicator {
  padding: 4px;
}
._3yt86I59ov .select__value-container {
  padding: 0 8px;
}
._3yt86I59ov._3hQerhYJ1U .select__control {
  color: #ff5c5c;
  border-color: #ff5c5c;
  background-color: #fff;
}
._3yt86I59ov._3hQerhYJ1U .select__control--is-focused {
  -webkit-box-shadow: 0 0 0 3px rgba(255,92,92,0.2);
  box-shadow: 0 0 0 3px rgba(255,92,92,0.2);
}
._3yt86I59ov._2zX_5bIKVg .select__control {
  color: #ffc464;
  border-color: #ffc464;
  background-color: #fff;
}
._3yt86I59ov._2zX_5bIKVg .select__control--is-focused {
  -webkit-box-shadow: 0 0 0 3px rgba(255,196,100,0.3);
  box-shadow: 0 0 0 3px rgba(255,196,100,0.3);
}
