._1vDuZMP24n {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
}
._1vDuZMP24n:last-of-type {
  padding-right: 0;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
._3fFLoTAoBo {
  font-weight: 600;
  white-space: nowrap;
}
