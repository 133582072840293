._2OrPGwJh1T {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
._2OrPGwJh1T ._19rpw3xwAy {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
}
._2OrPGwJh1T .TDdWdUWKDu {
  margin-bottom: 0.5rem;
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  width: 90%;
}
._23IZzwEdXE {
  width: 100%;
}
@media screen and (min-width: 769px) {
  ._2OrPGwJh1T {
    -webkit-box-lines: single;
    -moz-box-lines: single;
    -o-box-lines: single;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  ._2OrPGwJh1T ._19rpw3xwAy {
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
    margin-bottom: 0;
  }
  ._2OrPGwJh1T .TDdWdUWKDu {
    padding-right: 0.75rem;
  }
  ._2OrPGwJh1T ._19rpw3xwAy + .TDdWdUWKDu {
    -webkit-flex-basis: 70%;
    flex-basis: 70%;
  }
}
