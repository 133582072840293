.nvzBPEdZvP {
  text-align: center;
  display: block;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  -ms-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
.nvzBPEdZvP._2OHo4xQ6k_ {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.nvzBPEdZvP._3WjRY2y7pq {
  height: 1rem;
}
.nvzBPEdZvP._1uPePYhgrG {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.nvzBPEdZvP._1uPePYhgrG ._1fUdjhEh_4 {
  margin-right: 0.5rem;
}
._1fUdjhEh_4 {
  width: 2rem;
  height: 2rem;
  padding: 1px;
}
._3MWkqfxLmh,
._1_nMwMntwl {
  stroke-width: 4;
  fill: none;
  vector-effect: non-scaling-stroke;
}
._1_nMwMntwl {
  stroke: rgba(100,100,100,0.1);
}
._3MWkqfxLmh {
  stroke-dasharray: 40 50;
  stroke-linecap: round;
  -webkit-animation: UfbaSFMH6o 0.6s infinite linear;
  -moz-animation: UfbaSFMH6o 0.6s infinite linear;
  -o-animation: UfbaSFMH6o 0.6s infinite linear;
  -ms-animation: UfbaSFMH6o 0.6s infinite linear;
  animation: UfbaSFMH6o 0.6s infinite linear;
}
._30tsqsOKdj {
  stop-color: #2380ff;
}
.T0fZuSOklu ._30tsqsOKdj {
  stop-color: #777;
}
._3QfnvM1id2 {
  color: #868686;
}
._2CkAh579Pb {
  position: absolute;
  top: -2rem;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  height: 2rem;
}
._2CkAh579Pb.DoHsi8W2sE {
  height: 1.5rem;
  top: -1.5rem;
}
._2CkAh579Pb._3WjRY2y7pq {
  height: 1rem;
  top: -1rem;
}
.DoHsi8W2sE ._1fUdjhEh_4 {
  width: 1.5rem;
  height: 1.5rem;
}
._3WjRY2y7pq ._1fUdjhEh_4 {
  width: 1rem;
  height: 1rem;
}
._3WjRY2y7pq ._1fUdjhEh_4 ._3MWkqfxLmh,
._3WjRY2y7pq ._1fUdjhEh_4 ._1_nMwMntwl {
  stroke-width: 3.5;
}
@-moz-keyframes UfbaSFMH6o {
  from {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes UfbaSFMH6o {
  from {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes UfbaSFMH6o {
  from {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes UfbaSFMH6o {
  from {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -o-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  to {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
