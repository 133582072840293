._3H9PhXuFsD {
  white-space: nowrap;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
._3H9PhXuFsD:not(:first-of-type) {
  padding-right: 0.75rem;
}
._3H9PhXuFsD:first-of-type {
  padding-right: 0.25rem;
}
._3H9PhXuFsD:last-of-type {
  padding-right: 0;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 1;
  flex-shrink: 1;
  overflow-y: auto;
}
