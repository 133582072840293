._8GhCzWxxSr {
  padding: 0.25rem;
  line-height: 1;
  height: 1rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
._8GhCzWxxSr:not(:last-child) {
  margin-right: 1.5rem;
}
@media screen and (max-width: 768px) {
  ._8GhCzWxxSr:not(:last-child) {
    margin-right: 0.5rem;
  }
}
.QzmKV-3t65 {
  color: #686868;
  margin-right: 0.5rem;
}
._3y-ZDaNyXd {
  font-weight: 600;
  margin-right: 0.25rem;
}
@media screen and (max-width: 768px) {
  ._10Mhux0c7V {
    display: none;
  }
}
