._1uDvcgzCFN {
  height: 100%;
  width: 100%;
  position: relative;
}
._263y3odAHo {
  padding: 0;
}
._1bcagPii8q {
  border: 1px solid #efefef;
  border-radius: 3px;
}
._2d47khkFaY {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.iylL1Q6ECx {
  color: #868686;
}
ul._263y3odAHo,
ol._263y3odAHo {
  list-style: none;
  margin: 0;
}
._94EkRwJg0s {
  border-bottom: 1px solid #efefef;
}
._152Tfg38Hn {
  border-top: 1px solid #efefef;
}
._1rLzG2BJoe,
.L53ynqAL6a {
  padding: 0.25rem 0.5rem;
}
._20FU24ET91,
._1sDhxTjcCa {
  padding: 0.5rem 0.75rem;
}
._3o4ZJxsYrz,
._3GaARsewp9 {
  padding: 0.75rem 1rem;
}
