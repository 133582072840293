._2ErWTU8BDH {
  background-color: #fff;
  z-index: 2000;
  width: 100%;
}
@media screen and (max-width: 768px) {
  ._2ErWTU8BDH {
    z-index: 2010;
    position: fixed;
    top: 0;
  }
  ._2ErWTU8BDH + main {
    padding-top: 7rem;
  }
}
@media screen and (max-width: 1080px) {
  ._2ErWTU8BDH._3iDyjYFTWr {
    position: fixed;
    z-index: 2010;
    top: 0;
    left: 0;
    height: 100vh;
    background-color: #fff;
  }
  ._2ErWTU8BDH._3iDyjYFTWr .HFm1FVFDr0 img {
    width: 1.5rem;
  }
}
@media screen and (max-width: 768px) and (max-width: 768px) {
  ._2ErWTU8BDH + main {
    padding-top: 4rem;
  }
}
._25WWWYJNAN {
  border-bottom: 1px solid #efefef;
  background: -webkit-linear-gradient(bottom left, #f8f8f8 0%, #fff 60%);
  background: -moz-linear-gradient(bottom left, #f8f8f8 0%, #fff 60%);
  background: -o-linear-gradient(bottom left, #f8f8f8 0%, #fff 60%);
  background: -ms-linear-gradient(bottom left, #f8f8f8 0%, #fff 60%);
  background: linear-gradient(to top right, #f8f8f8 0%, #fff 60%);
  height: 7rem;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  padding: 0 2rem;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -o-box-flex: 0;
  box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
}
@media screen and (max-width: 768px) {
  ._25WWWYJNAN {
    height: 4rem;
    background-color: #fff;
    padding: 0 1rem;
  }
}
._8VEM4wWatt {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -o-box-flex: 0;
  -ms-box-flex: 0;
  box-flex: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
}
._8VEM4wWatt img {
  position: relative;
  bottom: 1px;
}
._1TKDgyTA2U {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: stretch;
  -moz-box-align: stretch;
  -o-box-align: stretch;
  -ms-flex-align: stretch;
  -webkit-align-items: stretch;
  align-items: stretch;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  padding: 0 3rem;
  -webkit-box-flex: 2;
  -moz-box-flex: 2;
  -o-box-flex: 2;
  -ms-box-flex: 2;
  box-flex: 2;
  -webkit-flex-grow: 2;
  flex-grow: 2;
  height: 100%;
  max-width: 36rem;
}
@media screen and (max-width: 1080px) {
  ._1TKDgyTA2U {
    display: none;
  }
}
@media screen and (max-width: 1080px) {
  ._3ufEp6bOfh {
    display: none;
  }
}
._2pKeXVp2vA {
  border: 0;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-appearance: none;
  text-align: inherit;
  display: none;
  width: 7rem;
  margin-right: -2rem;
  height: 100%;
  position: relative;
}
._2pKeXVp2vA .HFm1FVFDr0 {
  top: 50%;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  display: block;
  width: 2rem;
  height: 100%;
}
._2pKeXVp2vA .HFm1FVFDr0 img {
  top: 50%;
  left: 50%;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 2rem;
}
@media screen and (max-width: 1080px) {
  ._2pKeXVp2vA {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  ._2pKeXVp2vA {
    width: 4rem;
    margin-right: -1rem;
  }
}
.mO_yTTlONU {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  max-width: 42rem;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
._1RvT-TUJ7m {
  padding-left: 1.5rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  max-width: 25rem;
  -webkit-box-flex: 2;
  -moz-box-flex: 2;
  -o-box-flex: 2;
  -ms-box-flex: 2;
  box-flex: 2;
  -webkit-flex-grow: 2;
  flex-grow: 2;
}
._1RvT-TUJ7m > div:first-child {
  max-width: 15rem;
  border-color: #dfdfdf;
}
@media screen and (max-width: 1080px) {
  ._2CTtnHe2Nc {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    font-size: 0.001px;
    position: absolute;
    top: -1px;
    right: -1px;
  }
}
