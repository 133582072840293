._3Eg4azx3lm {
  display: block;
}
._3Eg4azx3lm::first-letter {
  text-transform: lowercase;
}
.KgQzZ77x0t {
  display: block;
}
.KgQzZ77x0t::first-letter {
  text-transform: uppercase;
}
._3lo96tYrhC {
  text-transform: uppercase;
}
._1oEJuo2Oi- {
  text-transform: lowercase;
}
._3p4TBWSItt {
  text-transform: capitalize;
}
