._1B4yjIrjJJ {
  background-color: #fff;
  height: 3rem;
  z-index: 1;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
._1B4yjIrjJJ:not(._2dJO0kXf7o) {
  border-bottom: 1px solid #efefef;
}
._1B4yjIrjJJ:not(._2dJO0kXf7o) ._2DZ3uHAQNZ {
  padding: 0.25rem 0 0.25rem 0.5rem;
}
._2DZ3uHAQNZ {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
.NFTZHJ9Twq {
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  right: 0.75rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: end;
  -moz-box-pack: end;
  -o-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}
@media screen and (min-width: 621px) {
  .NFTZHJ9Twq {
    position: sticky;
  }
}
.NFTZHJ9Twq ._1lP7n6gNgO {
  background-color: #fff;
}
