._3KNo5vyr47 {
  cursor: pointer;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: single;
  -moz-box-lines: single;
  -o-box-lines: single;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
._3KNo5vyr47:not(.G3H4KpSfbT):hover input ~ ._1PMY-nDgPN {
  background-color: #f3f3f3;
}
._3KNo5vyr47:not(.G3H4KpSfbT):hover input:checked ~ ._1PMY-nDgPN,
._3KNo5vyr47 input:checked:focus ~ ._1PMY-nDgPN {
  border-color: #3d8fff;
}
.G3H4KpSfbT {
  cursor: not-allowed;
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
}
._1yuWyNHzIv {
  position: relative;
  bottom: 1px;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  outline: 0;
}
._1yuWyNHzIv input {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
._1yuWyNHzIv input:disabled {
  cursor: not-allowed;
}
._1yuWyNHzIv input:checked ~ ._1PMY-nDgPN {
  border-color: #dfdfdf;
}
._1yuWyNHzIv input:checked ~ ._1PMY-nDgPN:after {
  opacity: 1;
  -ms-filter: none;
  filter: none;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -o-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 0.12s cubic-bezier(0.12, 0.4, 0.29, 1.4) 0.05s;
  -moz-transition: all 0.12s cubic-bezier(0.12, 0.4, 0.29, 1.4) 0.05s;
  -o-transition: all 0.12s cubic-bezier(0.12, 0.4, 0.29, 1.4) 0.05s;
  -ms-transition: all 0.12s cubic-bezier(0.12, 0.4, 0.29, 1.4) 0.05s;
  transition: all 0.12s cubic-bezier(0.12, 0.4, 0.29, 1.4) 0.05s;
}
._1yuWyNHzIv input:focus ~ ._1PMY-nDgPN {
  border-color: #2380ff;
}
._1PMY-nDgPN {
  border: 1px solid #dfdfdf;
  border-radius: 1.08rem;
  position: relative;
  top: 0;
  left: 0;
  display: block;
  width: 1.08rem;
  height: 1.08rem;
  -webkit-transition: all 0.12s;
  -moz-transition: all 0.12s;
  -o-transition: all 0.12s;
  -ms-transition: all 0.12s;
  transition: all 0.12s;
}
._1PMY-nDgPN:after {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  position: absolute;
  left: 4px;
  top: 4px;
  width: 7px;
  height: 7px;
  background-color: #3d8fff;
  border-radius: 100%;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -o-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.12s ease-out 0.05s;
  -moz-transition: all 0.12s ease-out 0.05s;
  -o-transition: all 0.12s ease-out 0.05s;
  -ms-transition: all 0.12s ease-out 0.05s;
  transition: all 0.12s ease-out 0.05s;
  content: '';
}
._3Bqev3XIMN {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
