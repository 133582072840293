._34jM_pJljD {
  margin: 1rem 0;
  height: 2rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: end;
  -moz-box-align: end;
  -o-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}
._2XW8Gw9MVQ {
  margin-top: 0;
}
._2TTr7yGG2a {
  font-size: 1.57rem;
  line-height: 1.4;
  font-weight: 800;
  position: relative;
  bottom: 3px;
  line-height: 0.8;
  height: 1rem;
  white-space: no-break;
  margin: 0;
  font-weight: 600;
  display: inline-block;
}
@media screen and (max-width: 550px) {
  .omjnfU9KmB {
    margin-right: -1rem;
    overflow: auto;
    background: -webkit-linear-gradient(right, rgba(0,0,0,0.1), #fff 5rem);
    background: -moz-linear-gradient(right, rgba(0,0,0,0.1), #fff 5rem);
    background: -o-linear-gradient(right, rgba(0,0,0,0.1), #fff 5rem);
    background: -ms-linear-gradient(right, rgba(0,0,0,0.1), #fff 5rem);
    background: linear-gradient(to left, rgba(0,0,0,0.1), #fff 5rem);
  }
}
