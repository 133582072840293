._1xsy0MJc8b:first-of-type {
  margin-left: calc(1rem + 0.75rem);
}
._1HvLq8GZ4Q {
  -webkit-flex-basis: 10%;
  flex-basis: 10%;
  min-width: 6rem;
}
._3p4D_hLPeo {
  -webkit-flex-basis: 5%;
  flex-basis: 5%;
  min-width: 4rem;
  max-width: 6rem;
}
._3r3ZEyHosq {
  -webkit-flex-basis: 20%;
  flex-basis: 20%;
  min-width: 14rem;
  max-width: 20rem;
}
.E3N1tju1Ut {
  -webkit-flex-basis: auto;
  flex-basis: auto;
}
