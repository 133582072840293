.SHqfsv_2GS {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.SHqfsv_2GS img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  object-position: left;
}
.SHqfsv_2GS ._8ZfFihtUo- {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  line-height: 0;
  width: 11rem;
  height: 2.3rem;
}
@media screen and (max-width: 768px) {
  .SHqfsv_2GS ._8ZfFihtUo- {
    width: 9rem;
    height: 2.1rem;
  }
}
.SHqfsv_2GS ._7T8u3XAzqF {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.SHqfsv_2GS ._2BljZFi77V {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.SHqfsv_2GS ._1UPQzWXzUL {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.SHqfsv_2GS:not(._2wQyrbJB6d)._4Z1FHuMULn ._7T8u3XAzqF {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-right: 1.5rem;
}
@media screen and (max-width: 768px) {
  .SHqfsv_2GS:not(._2wQyrbJB6d)._4Z1FHuMULn ._7T8u3XAzqF {
    padding-right: 1rem;
  }
}
@media screen and (max-width: 768px) {
  .SHqfsv_2GS:not(._2wQyrbJB6d)._4Z1FHuMULn ._7T8u3XAzqF ._8ZfFihtUo- {
    max-width: 7rem;
  }
}
.SHqfsv_2GS:not(._2wQyrbJB6d) ._2BljZFi77V {
  border-left: 1px solid #dfdfdf;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding-left: 1.5rem;
}
@media screen and (max-width: 768px) {
  .SHqfsv_2GS:not(._2wQyrbJB6d) ._2BljZFi77V {
    padding-left: 1rem;
  }
}
.SHqfsv_2GS:not(._2wQyrbJB6d) ._1UPQzWXzUL {
  width: 9rem;
  height: 1.9rem;
}
@media screen and (max-width: 768px) {
  .SHqfsv_2GS:not(._2wQyrbJB6d) ._1UPQzWXzUL {
    width: 6rem;
    height: 1.5rem;
  }
}
.SHqfsv_2GS._2wQyrbJB6d {
  max-width: 15rem;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 2rem;
}
.SHqfsv_2GS._2wQyrbJB6d._4Z1FHuMULn ._7T8u3XAzqF {
  border-bottom: 1px solid #dfdfdf;
  padding-bottom: 1.5rem;
  margin-bottom: 1.5rem;
  height: auto;
}
.SHqfsv_2GS._2wQyrbJB6d._4Z1FHuMULn ._8ZfFihtUo- {
  width: 100%;
  height: auto;
}
.SHqfsv_2GS._2wQyrbJB6d ._2BljZFi77V {
  margin-left: auto;
  margin-right: auto;
}
.SHqfsv_2GS._2wQyrbJB6d ._1UPQzWXzUL {
  display: block;
  height: 2.2rem;
}
