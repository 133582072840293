._1SBejEbD2O {
  display: block;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
._3L6QQtPsdb {
  width: 1.5rem;
  color: #686868;
  font-size: 1.1rem;
  position: relative;
  bottom: 2px;
}
._1o9FfPKy46 {
  -webkit-flex-basis: 5%;
  flex-basis: 5%;
  min-width: 4rem;
  max-width: 6rem;
  color: #868686;
}
._2oyxPH6Vf2 {
  -webkit-flex-basis: 10%;
  flex-basis: 10%;
  min-width: 6rem;
}
._2t4umWHVTK {
  -webkit-flex-basis: 20%;
  flex-basis: 20%;
  min-width: 14rem;
  max-width: 20rem;
}
._2l2brJYv3f {
  color: #f00;
}
.OeIbd-Chmq,
._2l2brJYv3f {
  -webkit-flex-basis: auto;
  flex-basis: auto;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -o-box-flex: 1;
  -ms-box-flex: 1;
  box-flex: 1;
  -webkit-flex-grow: 1;
  flex-grow: 1;
}
