.mf7DcaZFQ7 {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.mf7DcaZFQ7:not(:last-child) {
  margin-bottom: 0;
}
._1zXfBLYzE3 {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  white-space: nowrap;
  padding: 1rem 1.5rem;
  text-align: center;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: color 0.05s;
  -moz-transition: color 0.05s;
  -o-transition: color 0.05s;
  -ms-transition: color 0.05s;
  transition: color 0.05s;
}
._302rqmrT_5 {
  padding: 0.75rem 1rem;
}
._21w08g3NE6 {
  cursor: not-allowed;
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
}
._88laIMi_Oy:not(._3dVY5IOQ9d):before {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
 {
    -webkit-transform: scaleX(0);
    -moz-transform: scaleX(0);
    -o-transform: scaleX(0);
    -ms-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: -webkit-transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
    -moz-transition: -moz-transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
    -o-transition: -o-transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
    -ms-transition: -ms-transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
    transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
  }
  display: ;
  width: 100%;
  background: #efefef;
  content: '';
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -o-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
  -moz-transition: -moz-transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
  -o-transition: -o-transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
  -ms-transition: -ms-transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
  transition: transform 0.2s, width 0.4s cubic-bezier(1, 0, 0, 1) 0.2s;
}
._88laIMi_Oy:not(._3dVY5IOQ9d):hover:before {
  -webkit-transform: scaleX(1);
  -moz-transform: scaleX(1);
  -o-transform: scaleX(1);
  -ms-transform: scaleX(1);
  transform: scaleX(1);
}
._3dVY5IOQ9d {
  color: #006cff;
}
._3dVY5IOQ9d:before {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  display: block;
  width: 100%;
  background: #2380ff;
  content: '';
}
