._1f56j6C56i {
  position: relative;
  min-height: 2.1rem;
  overflow: hidden;
}
.ieir17bfhc {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  -webkit-box-pack: start;
  -moz-box-pack: start;
  -o-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
  font-family: 'IBM Plex Mono', monospace;
  font-size: 0.857rem;
  padding: 0.25rem 0 0.25rem 0.5rem;
  min-height: 2.1rem;
}
.ieir17bfhc,
.ieir17bfhc ._24qS1avW0W {
  -webkit-transition: background-color 0.12s linear;
  -moz-transition: background-color 0.12s linear;
  -o-transition: background-color 0.12s linear;
  -ms-transition: background-color 0.12s linear;
  transition: background-color 0.12s linear;
}
._1hQwlE1DKj {
  min-height: 1rem;
  padding: 0;
}
.R_YeqggFOI {
  cursor: pointer;
}
.ieir17bfhc:not(._1hQwlE1DKj):hover,
.ieir17bfhc:not(._1hQwlE1DKj):hover ._24qS1avW0W {
  background-color: #f3f3f3;
}
.ieir17bfhc:not(:hover) ._24qS1avW0W {
  background-color: #fafafa;
}
.ieir17bfhc ._24qS1avW0W {
  color: #aaa;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: center;
  -moz-box-pack: center;
  -o-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  min-height: 2.1rem;
  width: 2.1rem;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
.ieir17bfhc ._3GXo7UzgmX {
  color: #ff5c5c;
}
.ieir17bfhc ._3yn3rA-2IW {
  color: #2380ff;
}
