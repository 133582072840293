.JC8cyQQJM0 {
  padding: 0.5rem;
}
.JC8cyQQJM0 ._38V0W2Q4D7 {
  display: inline;
  margin-right: 0.25rem;
}
.JC8cyQQJM0 ._2HEkQzcfIM {
  padding: 0;
}
