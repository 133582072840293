._1T6w8sMUU1 {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: -0.75rem;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
  top: calc(0.75rem / 2);
}
@media screen and (max-width: 1080px) {
  ._1T6w8sMUU1 {
    top: 0;
    margin-top: 0.5rem;
  }
}
._1T6w8sMUU1 ._2eyz7rBSKg {
  margin-bottom: 0.75rem;
}
._1T6w8sMUU1 ._2eyz7rBSKg:not(:last-child) {
  margin-right: 1rem;
}
._1T6w8sMUU1._3a5SfLV8IK {
  -webkit-box-orient: horizontal;
  -moz-box-orient: horizontal;
  -o-box-orient: horizontal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
._1T6w8sMUU1:not(:last-child) {
  margin-bottom: 0;
}
