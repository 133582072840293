._2XdCorMnT_ {
  white-space: nowrap;
  padding: 0;
}
._2XdCorMnT_ .Bvr6-QGVUm {
  margin: 0;
}
.Bvr6-QGVUm {
  display: inline-block;
  color: #2380ff;
  font-weight: 600;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.Bvr6-QGVUm ._2oBhIn80AK:hover {
  background-color: #e6e6e6;
}
.Bvr6-QGVUm ._2oBhIn80AK.focus-visible {
  background-color: #e6e6e6;
}
.Bvr6-QGVUm ._2oBhIn80AK:focus {
  outline: 0;
}
.Bvr6-QGVUm ._2oBhIn80AK:active {
  background-color: #d9d9d9;
}
._2RjefUE7_A ._2oBhIn80AK {
  border-radius: 0 3px 3px 0;
  border: 1px solid #dfdfdf;
}
._1_dn5-7LG_ ._2oBhIn80AK {
  border-radius: 3px 0 0 3px;
}
._2nMUdGHh94 ._2oBhIn80AK {
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  cursor: not-allowed;
}
.rZqPAn3mlB ._2oBhIn80AK {
  background-color: #2380ff;
  border: 1px solid #2380ff;
  color: #fff;
}
.rZqPAn3mlB ._2oBhIn80AK.focus-visible {
  background-color: #006cff;
}
.rZqPAn3mlB ._2oBhIn80AK:focus {
  outline: 0;
}
.rZqPAn3mlB + .Bvr6-QGVUm a {
  border-left: 0;
}
.rZqPAn3mlB + ._2OpiXvHa4M {
  border-left: 0;
}
.rZqPAn3mlB ._2oBhIn80AK:hover {
  background-color: #2380ff;
}
._2OpiXvHa4M {
  color: #2380ff;
  padding: 0.5rem 1rem;
  border: 1px solid #dfdfdf;
  border-right: 0;
}
._2oBhIn80AK {
  padding: 0.5rem 1rem;
  outline: 0;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  display: inline-block;
  border: 1px solid #dfdfdf;
  border-right: 0;
}
