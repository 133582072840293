._3qr4B6D8N1 {
  border-right: 1px solid #efefef;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #fff;
  z-index: 2000;
}
@media screen and (min-width: 769px) {
  ._3qr4B6D8N1 {
    left: 0;
    top: 0;
    position: absolute;
    height: 100%;
  }
}
@media screen and (max-width: 768px) {
  ._3qr4B6D8N1 {
    height: auto;
    position: sticky;
    top: 4rem;
    z-index: 2000;
  }
}
._3qr4B6D8N1 ._3bT0wDFHNz {
  border: 0;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-appearance: none;
  text-align: inherit;
  -webkit-transition: color 0.05s;
  -moz-transition: color 0.05s;
  -o-transition: color 0.05s;
  -ms-transition: color 0.05s;
  transition: color 0.05s;
  border-top: 1px solid #efefef;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #868686;
  background-color: #fff;
  position: sticky;
  top: calc(100vh - 5rem);
  width: 100%;
  height: 5rem;
  margin-bottom: 1px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
._3qr4B6D8N1 ._3bT0wDFHNz:not(._2rQhdeS3Qz) {
  padding-left: 1.5rem;
  text-align: left;
}
._3qr4B6D8N1 ._2rQhdeS3Qz {
  padding-left: 0;
  text-align: center;
}
@media screen and (min-width: 769px) {
  ._3qr4B6D8N1 ._3bT0wDFHNz {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  ._3qr4B6D8N1 ._3bT0wDFHNz {
    display: none;
  }
}
._3qr4B6D8N1 ._3bT0wDFHNz:hover {
  color: #363636;
}
@media screen and (max-width: 768px) and (max-height: 500px) {
  ._3qr4B6D8N1 {
    position: static;
  }
}
._17lINpkZAU {
  -webkit-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
  -moz-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
  -o-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
  -ms-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
  transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
  -webkit-transition-duration: 330ms;
  -moz-transition-duration: 330ms;
  -o-transition-duration: 330ms;
  -ms-transition-duration: 330ms;
  transition-duration: 330ms;
  -webkit-transition-property: width;
  -moz-transition-property: width;
  -o-transition-property: width;
  -ms-transition-property: width;
  transition-property: width;
  position: sticky;
  top: 0;
  background-color: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  max-height: 100%;
}
@media screen and (min-width: 769px) {
  ._17lINpkZAU {
    padding-bottom: 5rem;
    width: 17rem;
    overflow-y: auto;
    overflow-x: hidden;
  }
}
@media screen and (max-width: 768px) {
  ._17lINpkZAU {
    height: 4rem;
    width: 100%;
  }
}
._3xl4yrSODp {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -o-box-orient: vertical;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}
@media screen and (min-width: 769px) {
  ._3xl4yrSODp {
    width: 3rem;
    display: -webkit-inline-box;
    display: -moz-inline-box;
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-box;
    display: inline-flex;
  }
  ._3xl4yrSODp ._2iLlipxUTq {
    padding: 0;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    height: 3rem;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -o-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  ._3xl4yrSODp ._2iLlipxUTq ._3SWNv6rF3z {
    display: none;
  }
  ._3xl4yrSODp ._2iLlipxUTq ._1wkXr7z5nl {
    margin-right: 0;
  }
}
._2iLlipxUTq {
  border-bottom: 1px solid #efefef;
  -webkit-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
  -moz-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
  -o-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
  -ms-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
  transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
  -webkit-transition-duration: 330ms;
  -moz-transition-duration: 330ms;
  -o-transition-duration: 330ms;
  -ms-transition-duration: 330ms;
  transition-duration: 330ms;
  -webkit-transition-property: padding;
  -moz-transition-property: padding;
  -o-transition-property: padding;
  -ms-transition-property: padding;
  transition-property: padding;
  height: 6rem;
  color: #363636;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 2rem 1.5rem;
  font-weight: 600;
}
._3vqILThwTA {
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media screen and (min-width: 769px) {
  ._3vqILThwTA {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  ._3vqILThwTA {
    border-bottom: 1px solid #efefef;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    padding: 1rem 0.75rem;
  }
}
._3vqILThwTA .nCOIMdAaOV {
  margin-right: 0.75rem;
  font-size: 2rem;
  color: #868686;
}
._1rL26Zk8KF {
  background-color: #fff;
  z-index: 2000;
  overflow: auto;
}
@media screen and (max-width: 768px) {
  ._1rL26Zk8KF {
    -webkit-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -moz-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -o-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -ms-transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    transition-timing-function: cubic-bezier(0.18, 0.71, 0.3, 0.99);
    -webkit-transition-duration: 330ms;
    -moz-transition-duration: 330ms;
    -o-transition-duration: 330ms;
    -ms-transition-duration: 330ms;
    transition-duration: 330ms;
    -webkit-transition-property: left;
    -moz-transition-property: left;
    -o-transition-property: left;
    -ms-transition-property: left;
    transition-property: left;
    position: fixed;
    top: 4rem;
    left: -60vw;
    bottom: 0;
    width: 60vw;
    max-width: 20rem;
  }
  .zWIUTQ3PjU {
    -webkit-box-shadow: 0 0 13rem 2rem rgba(0,0,0,0.4);
    box-shadow: 0 0 13rem 2rem rgba(0,0,0,0.4);
    left: 0;
  }
}
._3SWNv6rF3z {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
._1wkXr7z5nl {
  margin-right: 0.5rem;
}
@media screen and (max-width: 768px) {
  .YJdg-i16nS {
    overflow: hidden;
  }
}
