._14TkSwZnhM {
  margin-top: 6rem;
}
@media screen and (max-width: 768px) {
  ._14TkSwZnhM {
    margin-top: 1.5rem;
  }
}
._3OrH4wmVBn {
  margin-right: 1.5rem;
}
