._1BwkhT3lby {
  position: relative;
  display: inline-block;
  top: -2px;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  -ms-transition: background-color 0.25s;
  transition: background-color 0.25s;
}
._1BwkhT3lby:not(._2F6pzrYhu0) {
  margin-left: 0.5rem;
}
._1BwkhT3lby._2F6pzrYhu0 {
  margin-right: 0.5rem;
}
._1BwkhT3lby:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  width: 5px;
  height: 5px;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  -webkit-animation: _5cb-okWlhu 2s infinite;
  -moz-animation: _5cb-okWlhu 2s infinite;
  -o-animation: _5cb-okWlhu 2s infinite;
  -ms-animation: _5cb-okWlhu 2s infinite;
  animation: _5cb-okWlhu 2s infinite;
}
._12vEetn_PJ {
  background-color: #2380ff;
}
._-0Dq3M8FJn {
  background-color: #ff5c5c;
}
.MBAYgHzIAm {
  background-color: #ffc464;
}
._3MFXtociOR {
  background-color: #686868;
}
._1RK1P_Gxqn:after {
  -webkit-animation: _2mawig5g9b 2s infinite;
  -moz-animation: _2mawig5g9b 2s infinite;
  -o-animation: _2mawig5g9b 2s infinite;
  -ms-animation: _2mawig5g9b 2s infinite;
  animation: _2mawig5g9b 2s infinite;
}
._1WcVeq_EUS:after {
  -webkit-animation: _2zMggit7BK 2s infinite;
  -moz-animation: _2zMggit7BK 2s infinite;
  -o-animation: _2zMggit7BK 2s infinite;
  -ms-animation: _2zMggit7BK 2s infinite;
  animation: _2zMggit7BK 2s infinite;
}
._2s1vBLrZ1p:after {
  -webkit-animation: _1Cak4qYxpj 2s infinite;
  -moz-animation: _1Cak4qYxpj 2s infinite;
  -o-animation: _1Cak4qYxpj 2s infinite;
  -ms-animation: _1Cak4qYxpj 2s infinite;
  animation: _1Cak4qYxpj 2s infinite;
}
@-moz-keyframes _2mawig5g9b {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(35,128,255,0.4);
    box-shadow: 0 0 0 0 rgba(35,128,255,0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(35,128,255,0);
    box-shadow: 0 0 0 5px rgba(35,128,255,0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(35,128,255,0);
    box-shadow: 0 0 0 0 rgba(35,128,255,0);
  }
}
@-webkit-keyframes _2mawig5g9b {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(35,128,255,0.4);
    box-shadow: 0 0 0 0 rgba(35,128,255,0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(35,128,255,0);
    box-shadow: 0 0 0 5px rgba(35,128,255,0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(35,128,255,0);
    box-shadow: 0 0 0 0 rgba(35,128,255,0);
  }
}
@-o-keyframes _2mawig5g9b {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(35,128,255,0.4);
    box-shadow: 0 0 0 0 rgba(35,128,255,0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(35,128,255,0);
    box-shadow: 0 0 0 5px rgba(35,128,255,0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(35,128,255,0);
    box-shadow: 0 0 0 0 rgba(35,128,255,0);
  }
}
@keyframes _2mawig5g9b {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(35,128,255,0.4);
    box-shadow: 0 0 0 0 rgba(35,128,255,0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(35,128,255,0);
    box-shadow: 0 0 0 5px rgba(35,128,255,0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(35,128,255,0);
    box-shadow: 0 0 0 0 rgba(35,128,255,0);
  }
}
@-moz-keyframes _2zMggit7BK {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
    box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(255,92,92,0);
    box-shadow: 0 0 0 5px rgba(255,92,92,0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0);
    box-shadow: 0 0 0 0 rgba(255,92,92,0);
  }
}
@-webkit-keyframes _2zMggit7BK {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
    box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(255,92,92,0);
    box-shadow: 0 0 0 5px rgba(255,92,92,0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0);
    box-shadow: 0 0 0 0 rgba(255,92,92,0);
  }
}
@-o-keyframes _2zMggit7BK {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
    box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(255,92,92,0);
    box-shadow: 0 0 0 5px rgba(255,92,92,0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0);
    box-shadow: 0 0 0 0 rgba(255,92,92,0);
  }
}
@keyframes _2zMggit7BK {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
    box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(255,92,92,0);
    box-shadow: 0 0 0 5px rgba(255,92,92,0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0);
    box-shadow: 0 0 0 0 rgba(255,92,92,0);
  }
}
@-moz-keyframes _1Cak4qYxpj {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
    box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(255,92,92,0);
    box-shadow: 0 0 0 5px rgba(255,92,92,0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0);
    box-shadow: 0 0 0 0 rgba(255,92,92,0);
  }
}
@-webkit-keyframes _1Cak4qYxpj {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
    box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(255,92,92,0);
    box-shadow: 0 0 0 5px rgba(255,92,92,0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0);
    box-shadow: 0 0 0 0 rgba(255,92,92,0);
  }
}
@-o-keyframes _1Cak4qYxpj {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
    box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(255,92,92,0);
    box-shadow: 0 0 0 5px rgba(255,92,92,0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0);
    box-shadow: 0 0 0 0 rgba(255,92,92,0);
  }
}
@keyframes _1Cak4qYxpj {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
    box-shadow: 0 0 0 0 rgba(255,92,92,0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 5px rgba(255,92,92,0);
    box-shadow: 0 0 0 5px rgba(255,92,92,0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(255,92,92,0);
    box-shadow: 0 0 0 0 rgba(255,92,92,0);
  }
}
