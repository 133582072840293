._2cgIZJPsyB h3 {
  margin-top: 0;
}
._2cHlaN8MKI {
  display: block;
  margin-bottom: 1.5rem;
}
.VhDK5GkJAF {
  margin-bottom: 1.5rem;
}
._3G2Csz2Ufb > div:last-child {
  margin-bottom: 1.5rem;
}
@media screen and (max-width: 1080px) {
  ._3G2Csz2Ufb {
    -webkit-box-direction: reverse;
    -moz-box-direction: reverse;
    -o-box-direction: reverse;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column-reverse;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
  }
}
