._1LQumh101D:not(._1_6VSO-e-9):not(._26XLyzbolW) {
  text-decoration: none;
}
._1LQumh101D._1_6VSO-e-9 {
  color: #2380ff;
}
._1LQumh101D._1_6VSO-e-9:active,
._1LQumh101D._1_6VSO-e-9:hover {
  color: #006cff;
  text-decoration: underline;
}
._1LQumh101D._1_6VSO-e-9.c92zjfObA0:visited {
  color: #005fe1;
}
._1LQumh101D._26XLyzbolW {
  color: #868686;
}
._1LQumh101D._26XLyzbolW:active,
._1LQumh101D._26XLyzbolW:hover {
  color: #363636;
}
._1LQumh101D._26XLyzbolW.c92zjfObA0:visited {
  color: #363636;
}
._1LQumh101D._1_6VSO-e-9._3JY6dZKK85,
._1LQumh101D._26XLyzbolW._3JY6dZKK85 {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  filter: alpha(opacity=50);
  cursor: not-allowed;
}
.pfEWro8CR6 {
  position: relative;
  bottom: 2px;
  margin-left: 0.25rem;
  font-size: 0.9rem;
}
.BB6CuuabsT {
  position: relative;
  bottom: 1px;
  margin-right: 0.1rem;
}
