.A4kT04aTgP {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
._24fjPMu7yF {
  border: 1px solid #dfdfdf;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  background: #fff;
  border-radius: 3px;
  padding: 0;
  position: relative;
  -webkit-transition: border-color 0.12s;
  -moz-transition: border-color 0.12s;
  -o-transition: border-color 0.12s;
  -ms-transition: border-color 0.12s;
  transition: border-color 0.12s;
  overflow: hidden;
  overflow-y: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
._24fjPMu7yF:not(._1RjaHaNcKu) {
  max-height: 2.3rem;
}
._24fjPMu7yF._22M9bUW_1- {
  border-color: #2380ff;
  color: #000;
}
._24fjPMu7yF._290A-0hk5d {
  border-color: #ff5c5c;
  background-color: #fff;
}
._24fjPMu7yF._290A-0hk5d._22M9bUW_1- {
  -webkit-box-shadow: 0 0 0 3px rgba(255,92,92,0.2);
  box-shadow: 0 0 0 3px rgba(255,92,92,0.2);
}
._24fjPMu7yF._1ZWn3Vz360 {
  border-color: #ffc464;
  background-color: #fff;
}
._24fjPMu7yF._1ZWn3Vz360._22M9bUW_1- {
  -webkit-box-shadow: 0 0 0 3px rgba(255,196,100,0.3);
  box-shadow: 0 0 0 3px rgba(255,196,100,0.3);
}
._24fjPMu7yF._3W3oVG_b9L {
  background-color: #f3f3f3;
  opacity: 0.6;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=60)";
  filter: alpha(opacity=60);
}
._24fjPMu7yF._3W3oVG_b9L ._1HFYNaRroP {
  cursor: not-allowed;
}
._24fjPMu7yF._1WS_IK5GGj {
  background-color: #f3f3f3;
}
._24fjPMu7yF._1ywUNgOOwD {
  border-radius: 3px 0 0 3px;
}
::placeholder {
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 500;
  color: #868686;
  letter-spacing: initial;
  font-variant: normal;
  font-size: 1rem;
}
._1HFYNaRroP {
  font: inherit;
  background: none;
  border: 0;
  color: inherit;
  outline: 0;
  width: 100%;
  padding: 0 0.5rem;
  display: inline-block;
  -webkit-box-flex: 2;
  -moz-box-flex: 2;
  -o-box-flex: 2;
  -ms-box-flex: 2;
  box-flex: 2;
  -webkit-flex-grow: 2;
  flex-grow: 2;
  z-index: 1;
  height: 2.3rem;
  min-width: 0;
}
._1HFYNaRroP[type='password'] {
  letter-spacing: 1px;
  font-variant: small-caption;
  font-size: 16px;
}
.OZsdkCB6gR {
  padding: 0 0.5rem;
  position: relative;
  top: 3px;
}
.yvIdKiZrEK {
  height: 0.8rem;
  display: inline-block;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -o-box-flex: 0;
  -ms-box-flex: 0;
  box-flex: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  padding-right: 0.75rem;
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  -ms-transition: opacity 0.25s;
  transition: opacity 0.25s;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
}
.yvIdKiZrEK._2mngaI4ARe {
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
.yvIdKiZrEK path {
  fill: #2380ff;
}
._1xoo4gZ3Tp,
.QQxp4zmo0c,
.l9El5L_1Dq {
  font-family: 'IBM Plex Mono', Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
}
._1xoo4gZ3Tp {
  font-weight: 600;
  letter-spacing: 0.05rem;
}
.QQxp4zmo0c {
  font-size: 0.857rem;
}
.l9El5L_1Dq {
  position: absolute;
  left: 0.7rem;
  white-space: pre;
  color: #868686;
  z-index: 0;
  pointer-events: none;
}
._DyhsnTFcv {
  position: relative;
  top: 1px;
  padding-left: 0.5rem;
}
textarea._1HFYNaRroP {
  padding: 0.5rem;
  min-height: 5rem;
  height: auto;
}
._38EfL1X9zS {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
}
._38EfL1X9zS ._2Merk17S_4 {
  border-radius: 0 3px 3px 0;
  height: 100%;
  margin-left: -1px;
}
