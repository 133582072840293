.-mdz6SVjQc:not(:last-child) {
  border-bottom: 1px solid #efefef;
}
._22cJcUndwQ {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -o-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -o-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  padding: 2rem 0;
}
._22cJcUndwQ ._39ik4kGmqD {
  padding-right: 0.75rem;
}
._22cJcUndwQ ._3BYTdZDnsv {
  margin-top: 0;
  margin-bottom: 0.75rem;
}
._22cJcUndwQ ._3Xg72PH4L_ {
  margin-top: calc(0rem - 0.570724rem);
}
._22cJcUndwQ ._38tEj848gj {
  min-width: 5.5rem;
}
._39ik4kGmqD {
  display: block;
}
