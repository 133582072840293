._2q5PdgYJqi {
  display: -webkit-inline-box;
  display: -moz-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-box;
  display: inline-flex;
  min-width: 60px;
}
._3sRAtZ7rqF {
  margin: 0 0.25rem 0 0.5rem;
}
