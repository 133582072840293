._1ON6YSV1l8 {
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
._1ON6YSV1l8 ._3--ysk-mrp {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
  margin-bottom: 0.5rem;
}
._1ON6YSV1l8 ._2owKeVLMBo {
  -webkit-flex-basis: 100%;
  flex-basis: 100%;
}
@media screen and (min-width: 769px) {
  ._1ON6YSV1l8 {
    -webkit-box-lines: single;
    -moz-box-lines: single;
    -o-box-lines: single;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  ._1ON6YSV1l8 ._3--ysk-mrp {
    -webkit-flex-basis: 30%;
    flex-basis: 30%;
    margin-right: 0.5rem;
    margin-bottom: 0;
  }
  ._1ON6YSV1l8 ._3--ysk-mrp + ._2owKeVLMBo {
    -webkit-flex-basis: 70%;
    flex-basis: 70%;
  }
}
