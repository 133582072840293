@media screen and (min-width: 481px) {
  ._42lGJaHn3I._2kSsRuN1-_ {
    -webkit-box-orient: horizontal;
    -moz-box-orient: horizontal;
    -o-box-orient: horizontal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -o-box-pack: justify;
    -ms-flex-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-lines: single;
    -moz-box-lines: single;
    -o-box-lines: single;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  ._42lGJaHn3I._2kSsRuN1-_ ._3G1AvIlkPs {
    font-weight: 500;
    padding-top: 0;
  }
  ._42lGJaHn3I._2kSsRuN1-_ ._67IwSZkadH {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -o-box-flex: 1;
    -ms-box-flex: 1;
    box-flex: 1;
    -webkit-flex-grow: 1;
    flex-grow: 1;
    width: calc(100% - 14rem);
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  ._42lGJaHn3I._2kSsRuN1-_ ._3zxpvrtTCx {
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: box;
    display: flex;
    width: 14rem;
    font-weight: 500;
  }
}
._2kSsRuN1-_ {
  margin-bottom: 1.5rem;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: box;
  display: flex;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -o-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}
._2kSsRuN1-_ ._3zxpvrtTCx {
  font-weight: 600;
}
._2kSsRuN1-_ ._3zxpvrtTCx::after {
  display: none;
}
._2kSsRuN1-_._1u7Apgw5Ht ._3zxpvrtTCx::after {
  content: '·';
  display: inline;
  margin-left: 0.25rem;
  color: #2380ff;
  font-weight: 800;
}
._2kSsRuN1-_._42lGJaHn3I ._3G1AvIlkPs,
._2kSsRuN1-_._42lGJaHn3I .l0F3zQb_OG,
._2kSsRuN1-_._42lGJaHn3I ._3zxpvrtTCx::after {
  padding-top: 0.25rem;
}
._3zxpvrtTCx {
  display: block;
  width: 100%;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
._2OR9Kaw91v {
  font-weight: 500;
  color: #868686;
}
._67IwSZkadH {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  width: 100%;
}
._1CavvFkvyU {
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  width: 100%;
  -webkit-box-ordinal-group: 3;
  -moz-box-ordinal-group: 3;
  -o-box-ordinal-group: 3;
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
  margin-top: calc(0.5rem - 0.570724rem);
  margin-bottom: -0.5rem;
}
._3zVCww-n-D {
  display: block;
  font-size: 0.9rem;
  height: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  -ms-transition: opacity 0.25s;
  transition: opacity 0.25s;
}
._3zVCww-n-D span {
  display: inline-block;
}
._3zVCww-n-D ._3FVHI6ktVU {
  font-weight: 600;
}
._3zVCww-n-D._3dSCKyNor3 {
  height: auto;
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  -ms-transition: opacity 0.25s;
  transition: opacity 0.25s;
  opacity: 1;
  -ms-filter: none;
  filter: none;
}
._3zVCww-n-D ._3xefsl1A0O {
  font-size: 1.15rem;
  margin-right: 0.25rem;
  vertical-align: text-top;
  -webkit-box-flex: 0;
  -moz-box-flex: 0;
  -o-box-flex: 0;
  -ms-box-flex: 0;
  box-flex: 0;
  -webkit-flex-grow: 0;
  flex-grow: 0;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
}
._1hpR_Cnl52 {
  color: #ff5c5c;
}
._3O3z6gqcwy {
  color: #d18100;
}
.IoT1LMmN-q .l0F3zQb_OG {
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
}
