ul._3f1MHdX5iu {
  border: 1px solid #efefef;
  z-index: 2000;
  right: 0;
  position: absolute;
  background: #fff;
  padding: 0;
  min-width: 14rem;
  z-index: 9;
  margin: 0;
}
ul._3f1MHdX5iu._2y6rgXM3hD li._1b-9DOByT- > a,
ul._3f1MHdX5iu._2y6rgXM3hD li._1b-9DOByT- > button {
  padding: 1.5rem;
}
ul._3f1MHdX5iu hr {
  margin-top: 0;
  height: 0.4rem;
}
ul._3f1MHdX5iu li._1b-9DOByT- {
  display: block;
  margin-bottom: 0;
}
ul._3f1MHdX5iu li._1b-9DOByT- button {
  border: 0;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font: inherit;
  line-height: normal;
  -webkit-appearance: none;
  text-align: inherit;
}
ul._3f1MHdX5iu li._1b-9DOByT- > a,
ul._3f1MHdX5iu li._1b-9DOByT- > button {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1;
  display: block;
  text-decoration: none;
  padding: 1rem;
  width: 100%;
}
ul._3f1MHdX5iu li._1b-9DOByT- > a:not(._2DOLMasqdi),
ul._3f1MHdX5iu li._1b-9DOByT- > button:not(._2DOLMasqdi) {
  color: #363636;
}
ul._3f1MHdX5iu li._1b-9DOByT- ._2yt1VgrB7l {
  margin-right: 0.5rem;
}
ul._3f1MHdX5iu li._1b-9DOByT-:not(:last-child) {
  border-bottom: 1px solid #efefef;
}
ul._3f1MHdX5iu li._1b-9DOByT-:hover {
  background-color: #2380ff;
}
ul._3f1MHdX5iu li._1b-9DOByT-:hover a,
ul._3f1MHdX5iu li._1b-9DOByT-:hover button {
  color: #fff;
}
ul._3f1MHdX5iu li._1b-9DOByT-:hover ._2yt1VgrB7l {
  color: #fff;
}
._2DOLMasqdi {
  border-left: #2380ff 5px solid;
  color: #2380ff;
}
