._3yos0FGWu6 {
  display: inline-block;
  font-family: 'IBM Plex Mono', monospace;
  color: #000;
}
._3ZECJUNikT:before {
  content: '[ ';
}
._3ZECJUNikT:after {
  content: ' ]';
}
.ojrumTJa8p:before {
  content: '{ ';
}
.ojrumTJa8p:after {
  content: ' }';
}
._38gfEy8iQ3 {
  color: #f5a623;
}
._1aGrbQLNDg {
  color: #57a700;
}
.Y4wIEtU39c {
  color: #57a700;
}
._-9YcDhL5RW {
  padding-right: 0.5rem;
}
._-9YcDhL5RW:after {
  content: ':';
}
._1Dcf2YD2iA:not(:last-of-type) {
  padding-right: 0.5rem;
}
._1Dcf2YD2iA:not(:last-of-type):after {
  color: #000;
  content: ',';
}
._10rKweWPhR {
  color: #ff5c5c;
}
